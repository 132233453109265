.ContactForm {
	&__link {
		color: $black;
		font-weight: 700;
		&:hover,
		:focus {
			filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
			color: $black;
		}
	}
}

.contactForm {
	overflow: hidden;
	.form {
		select,
		input[type='text'],
		input[type='email'],
		input[type='password'],
		input[type='tel'],
		input[type='date'],
		input[type='time'],
		input[type='number'],
		input[type='url'],
		textarea {
			border-color: $white;
		}
	}

	&__subHeading {
		@extend %fs-18;
		margin: 0;
		text-align: center;
		color: $black;
		font-weight: 400;
	}
	&__heading {
		margin-bottom: 0;
		text-align: center;
		color: $black;
		font-weight: bold;
	}
	&__field--button {
		margin-top: 0;
	}

	&__button {
		width: 100%;
		margin-top: 2rem;
	}
}
