.header {
	z-index: 99;
	position: fixed;
	top: 0;
	width: 100%;
	background-color: $grey;
	border-bottom: 1px solid $mid-grey;
	max-height: 140px;

	@include media-max($medium) {
		max-height: 110px;
	}
	@include media-max($ipad) {
		position: relative;
	}
	@include media($ipad) {
		&__wrapper {
			padding-top: 1.4rem;
			padding-bottom: 1.5rem;
		}
	}

	.-scrolled & {
		height: 140px;
	}

	+ .main {
		@include media($ipad) {
			padding-top: 140px;
		}
	}
}

/*  ==========================================================================
	## LOGO
	======================================================================= */

.brandContainer {
	@include transition();
	flex-grow: 1;
	margin: 0.5rem 0 0;

	@include media($small) {
		margin: 0.5rem 0;
	}

	&__link {
		display: inline-block;
	}

	&__image {
		@include transition();
		@include fp(
			'max-width',
			120px,
			120px,
			$small,
			$medium,
			$remove-start: false,
			$remove-end: true
		);
		@include fp(
			'max-width',
			180px,
			180px,
			$medium,
			$ipad,
			$remove-start: true,
			$remove-end: true
		);
		@include fp(
			'max-width',
			180px,
			180px,
			$ipad,
			$max,
			$remove-start: true,
			$remove-end: false
		);

		.-scrolled & {
			max-width: 140px;
		}
	}
}

/*  ==========================================================================
	## MAIN NAVIGATION
	======================================================================= */

.mainNav {
	@include media-max($medium) {
		display: none;
	}

	&__list {
		margin: 0;
		padding: 0;
		text-align: right;
		list-style-type: none;
	}

	&__item {
		display: none;
		position: relative;
		transition-delay: 0s;
		color: $black;
		&:hover {
			background-color: $secondary;
			color: $white;
		}

		&.-showInMainMenu {
			display: inline-block;
			&:nth-child(-n + 5) .subNav .subNav {
				left: 100%;
			}
		}

		> .subNav {
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			width: 250px;
			transition: 50ms;

			max-height: 0;
			opacity: 0;
			visibility: hidden;
			z-index: 1;

			border-radius: 0 0 15px;
			@include media-max($ipad) {
				right: 0;
				left: unset;
			}
			.subNav__item:last-child > a {
				border-radius: 0 0 16px;
			}
		}

		// hovering the top menu
		&:focus,
		&:hover,
		&:focus-within {
			transition: background-color 500ms ease-in-out;
			border-radius: 0 6px 0 0;
			> .subNav {
				transition: all $base-duration;
				opacity: 1;
				visibility: visible;
			}

			&::before {
				border-bottom-color: #fff;
			}
		}
		.subNav__link.-is-current + .subNav,
		.subNav__link.-is-section + .subNav {
			display: block !important;
			> li > a {
				padding-left: 2rem;
			}
		}
	}

	&__link {
		@extend %fs-20;
		display: inline-block;
		position: relative;
		padding: 0.4rem 0.3rem;
		color: inherit;
		&:hover {
			color: inherit;
		}
		@include media($ipad) {
			padding: 0.4rem 0.6rem;
		}
		@include media-max($ipad) {
			font-size: 16px;
		}

		&.-is-current,
		&.-is-section {
			border-bottom: 1px solid $black;
		}
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all 250ms ease-in-out;
			background-color: none;
			border-radius: 0 6px 0 0;
			color: white;
		}
	}
}
.subNav {
	list-style: none;
	text-align: left;
	.subNav {
		height: 0;
		opacity: 0;
		visibility: none;
		position: absolute;
		overflow: hidden;
		left: -100%;
		padding: 0 0.5rem;
		width: 250px;
		top: 0;
		&:hover,
		&:focus {
			opacity: 1;
			visibility: 1;
			height: auto;
		}
	}
	&__item {
		display: none;
		position: relative;
		&.-showInMainMenu {
			display: block;
		}
	}

	&__link {
		display: block;
		padding: 0.5rem 1rem;
		background-color: $grey;
		color: $black;
		text-decoration: none;

		&:hover,
		&:focus {
			background-color: $secondary;
			color: $white;
			+ .subNav {
				opacity: 1;
				visibility: 1;
				height: auto;
			}
		}
		&.-is-section {
			background-color: $white;
			color: $black;
			&:hover {
				background-color: $secondary;
				color: $white;
			}
		}
		&.-is-current {
			font-weight: bold;
			background-color: $primary;
			color: $white;
		}
		&.-hasChildren {
			padding-right: 2rem;

			&.-getsIcon {
				&:after {
					position: absolute;
					font-family: 'Font Awesome 5 Pro';
					font-weight: 300;
					color: inherit;
					content: '\f105';
					right: 1rem;
					font-size: 22px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.headerIcons {
	z-index: 0;
	@include media-max($ipad) {
		position: absolute;
		top: 20px;
		right: 0.3rem;
	}
	@include media($medium) {
		z-index: 10000;
	}

	&__icon {
		@include transition();
		cursor: pointer;
		padding: 0.5rem;
		margin-bottom: 0;
		margin-top: 0.4rem;
		font-size: 30px;
		color: $secondary;
		@include media($medium) {
			font-size: 20px;
			margin-top: 0;
			margin-bottom: 0.4rem;
		}

		&:hover,
		&:focus {
			color: $black;
		}
		&.-search-hider {
			@include media-max($medium) {
				display: none;
			}
		}
	}
}

.header.-hasBackgroundImage {
	background: none;
	border-bottom: none;

	.mainNav {
		&__item {
			&:hover,
			&:focus {
				background-color: $secondary;
			}
		}

		&__link {
			font-weight: bold;
			color: $white;
			filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));

			&.-is-current,
			&.-is-section {
				color: $white;
				border-bottom: 2px solid $white;
			}

			&::before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transition: all 250ms ease-in-out;
				background-color: none;
				border-radius: 0 6px 0 0;
			}
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 116px;

		background-image: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.6),
			rgba(0, 0, 0, 0)
		);
		@include media($medium) {
			height: 140px;
		}
	}

	.headerIcons {
		&__icon {
			color: $white;
			cursor: pointer;
			filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
			&:hover {
				color: $white;
			}
			&:focus {
				color: $white;
				outline: 2px solid $primary; //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			}
		}
	}

	+ .main {
		@include media($ipad) {
			padding-top: 0;
		}
	}

	.skip-to-content {
		color: $white;
		&:focus {
			color: $white;
		}
	}
}

.loginLinks {
	@extend %fs-16;
	display: block;
	opacity: 0;
	max-height: 0;
	visibility: hidden;
	position: absolute;
	z-index: 10000;
	top: 100%;
	right: 0;
	width: 250px;
	padding: 0;
	border-radius: 0 0 15px;
	overflow: hidden;
	list-style: none;
	background-color: $white;
	&__item {
		background-color: $white;
		min-height: 44px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&__link {
		display: block;
		padding: 0 1rem;
		min-height: 44px;
		line-height: 44px;
		color: $black;
		text-decoration: none;
		&:hover,
		&:focus {
			background-color: $secondary;
			color: $white;
		}
	}
}

.JS-loginToggle.-is-active {
	.loginLinks {
		opacity: 1;
		max-height: 1000px;
		visibility: visible;
		transition: all $base-duration;
	}
}

.skip-to-content {
	position: absolute;
	top: 90%;
	left: 150%;
	font-weight: 700;
	@include media-max($ipad) {
		display: none;
	}
	&:focus {
		left: 1%;
		color: $primary;
	}
}
